function initTab(elem){
    document.addEventListener('click', function (e) {
        if (!e.target.matches(elem+' .tab-button')) return;
        else{
            if(!e.target.classList.contains('active')){
                findActiveElementAndRemoveIt(elem+' .tab-button');
                findActiveElementAndRemoveIt(elem+' .tab-panel');
                e.target.classList.add('active');  
                setTimeout(function(){                 
                    var panel = document.querySelectorAll(elem+' .tab-panel.'+e.target.dataset.name);
                    Array.prototype.forEach.call(panel, function (el) {
                        el.classList.add('active');
                    });
                }, 200);
            }
        }
    });
}

function findActiveElementAndRemoveIt(elem){
    var elementList = document.querySelectorAll(elem);
    Array.prototype.forEach.call(elementList, function (e) {
        e.classList.remove('active');
    });
}

initTab('.tabs');

