// Initialise Slick slider

( function( $, window, document, undefined ) {

	const prevArrowHTML = `<button type="button" class="slick-prev" aria-label="Previous slide"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" aria-hidden="true" focusable="false"><path d="M13.891 17.418c0.268 0.272 0.268 0.709 0 0.979s-0.701 0.271-0.969 0l-7.83-7.908c-0.268-0.27-0.268-0.707 0-0.979l7.83-7.908c0.268-0.27 0.701-0.27 0.969 0s0.268 0.709 0 0.979l-7.141 7.419 7.141 7.418z"></path></svg></button>`;
	const nextArrowHTML = `<button type="button" class="slick-next" aria-label="Next slide"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" aria-hidden="true" focusable="false"><path d="M13.25 10l-7.141-7.42c-0.268-0.27-0.268-0.707 0-0.979 0.268-0.27 0.701-0.27 0.969 0l7.83 7.908c0.268 0.271 0.268 0.709 0 0.979l-7.83 7.908c-0.268 0.271-0.701 0.27-0.969 0s-0.268-0.707 0-0.979l7.141-7.417z"></path></svg></button>`;

	const defaultArgs = {
		autoplay: false,
		dots: false,
		arrows: true,
		prevArrow: prevArrowHTML,
		nextArrow: nextArrowHTML,
		infinite: true,
		adaptiveHeight: false,
		draggable: false
	};

	const $imageGallery = $('.js-image-carousel');

	if ( $imageGallery.length ) {
		$imageGallery.slick( $.extend( {}, defaultArgs, {
			autoplay: true,
			autoplaySpeed: 4000,
			draggable: true
		} ) );
	} // /$imageGallery.length


	const prevArrow2HTML = `<button type="button" class="slick-prev" aria-label="Previous slide"><svg style="transform: scaleX(-1);" transform="rotate(180)" xmlns="http://www.w3.org/2000/svg" width="40.865" height="66.178" viewBox="0 0 40.865 66.178">
	<path id="Path_2" data-name="Path 2" d="M8.59,64.4,33.848,39.089,8.59,13.776,16.366,6,49.455,39.089,16.366,72.178Z" transform="translate(-8.59 -6)" fill="#092c60"/>
  </svg>
  
  
  </button>`;
	const nextArrow2HTML = `<button type="button" class="slick-next" aria-label="Next slide"><svg xmlns="http://www.w3.org/2000/svg" width="40.865" height="66.178" viewBox="0 0 40.865 66.178">
	<path id="Path_2" data-name="Path 2" d="M8.59,64.4,33.848,39.089,8.59,13.776,16.366,6,49.455,39.089,16.366,72.178Z" transform="translate(-8.59 -6)" fill="#092c60"/>
  </svg>
  
  
  </button>`;


	const $imageCarousel = $('.js-house-carousel');

	if ( $imageCarousel.length ) {

		$imageCarousel.slick( {
			autoplay: false,
			dots: false,
			arrows: true,
			prevArrow: prevArrow2HTML,
			nextArrow: nextArrow2HTML,
			infinite: true,
			adaptiveHeight: false,
			draggable: true,
			slidesToShow: 1,
			centerMode: true,
			centerPadding: '17%',
			responsive: [
				{
					breakpoint: 768,
					settings: {
						centerMode: false,
						centerPadding: 0,
						arrows: false,
						adaptiveHeight: true
					}
				}
			]
		} );

	} // /$imageCarousel.length
	
} )( jQuery, window, document );
